import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FcLike, FcComments } from "react-icons/fc";


function PostDetails() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');

    useEffect(() => {
        fetchPostAndComments();
    }, [postId]);

    const fetchPostAndComments = async () => {
        try {
            const response = await axios.get(`https://api.mangacademy.com/api/posts/${postId}`);
            // const response = await axios.get(`http://localhost:3002/api/posts/${postId}`);

            const fetchedPost = response.data.post;
            if (fetchedPost) {
                fetchedPost.formattedDate = formatDate(fetchedPost.created_at);
            }

            // Formatage des dates des commentaires
            const fetchedComments = response.data.comments.map(comment => {
                return {
                    ...comment,
                    formattedDate: formatDate(comment.created_at)
                };
            });
            setPost(fetchedPost);
            setComments(fetchedComments);
        } catch (error) {
            console.error('Erreur lors de la récupération du post et des commentaires', error);
        }
    };

    const handleNewCommentChange = (e) => {
        setNewComment(e.target.value);
    };

    const handleSubmitComment = async (e) => {
        e.preventDefault();
        if (!newComment) return;

        const user_id = localStorage.getItem('userId');

        try {
            await axios.post(`https://api.mangacademy.com/api/posts/${postId}/comment`, {
            // await axios.post(`http://localhost:3002/api/posts/${postId}/comment`, {
                user_id: user_id,
                content: newComment
            });

            setNewComment('');
            fetchPostAndComments();
        } catch (error) {
            console.error('Erreur lors de la soumission du commentaire', error);
        }
    };
    const handleLikeClick = async (postId) => {
        const user_id = localStorage.getItem('userId');
        try {
            await axios.post(`https://api.mangacademy.com/api/posts/${postId}/like`, { user_id });
            // await axios.post(`http://localhost:3002/api/posts/${postId}/like`, { user_id });
        } catch (error) {
            console.error('Erreur lors du like', error);
        }
    };

    function formatDate(dateString) {
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const months = ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"];

        const date = new Date(dateString);
        const dayName = days[date.getUTCDay()];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const hours = date.getUTCHours();
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);

        return `${dayName} ${day} ${month} ${hours}:${minutes}`;
    }


    return (
        <div className='post-details'>
            {post && (
                <div className='individualpost'>
                    <div className='poster'>
                        <img src={post.profilePictureUrl} alt={`${post.username}'s profile`} />
                        <div className='post-username'>{post.username}</div>
                        <div className='post-date'>{post.formattedDate}</div>
                    </div>
                    <div className='post-content'>{post.content}</div>
                    <div className='post-interactions'>
                        <span onClick={() => handleLikeClick(post.post_id)}>
                            <FcLike />
                        </span>
                        <span>{post.likes_count} J'aime</span>
                    </div>
                </div>
            )}

            <div className='comments-section'>
                <h3>Commentaires</h3>
                {comments.map(comment => (
                    <div key={comment.comment_id} className='individual-comment'>
                        <div className='commenter'>
                            <img src={comment.profilePictureUrl} alt={`${comment.username}'s profile`} />
                            <div className='comment-username'>{comment.username}</div>
                            <div className='comment-date'>{comment.formattedDate}</div>
                        </div>
                        <div className='comment-content'>{comment.content}</div>
                    </div>
                ))}

                <form onSubmit={handleSubmitComment}>
                    <textarea value={newComment} onChange={handleNewCommentChange} placeholder="Ajoutez un commentaire..." />
                    <button type="submit">Envoyer</button>
                </form>
            </div>
        </div>
    );
}

export default PostDetails;
