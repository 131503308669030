import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import axios from 'axios';
import { FcLike, FcComments } from "react-icons/fc";


function Feed() {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [posts, setPosts] = useState([]);
    const [newPostContent, setNewPostContent] = useState('');

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        try {
            const response = await axios.get('https://api.mangacademy.com/api/posts');
            // const response = await axios.get('http://localhost:3002/api/posts');
            setPosts(response.data.map(post => ({
                ...post,
                formattedDate: formatDate(post.created_at)
            })));
            console.log(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des posts', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };
    const GotoProfile = () => {
        navigate('/profile');
    };


    const handleNewPostChange = (e) => {
        setNewPostContent(e.target.value);
    };

    const handleSubmitPost = async (e) => {
        e.preventDefault();
        if (!newPostContent) return;
        const user_id_local = localStorage.getItem('userId');

        try {
            await axios.post('https://api.mangacademy.com/api/posts', { user_id: user_id_local, content: newPostContent });
            // await axios.post('http://localhost:3002/api/posts', { user_id: user_id_local, content: newPostContent });
            setNewPostContent('');
            fetchPosts();
        } catch (error) {
            console.error('Erreur lors de la publication du post', error);
        }
    };

    const handleLikeClick = async (postId) => {
        const user_id = localStorage.getItem('userId');
        try {
            await axios.post(`https://api.mangacademy.com/api/posts/${postId}/like`, { user_id });
            // await axios.post(`http://localhost:3002/api/posts/${postId}/like`, { user_id });
        } catch (error) {
            console.error('Erreur lors du like', error);
        }
    };
    const goToPost = (postId) => {
        navigate(`/post/${postId}`);
    };



    function formatDate(dateString) {
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
        const months = ["Jan", "Fév", "Mar", "Avr", "Mai", "Jun", "Jul", "Aoû", "Sep", "Oct", "Nov", "Déc"];

        const date = new Date(dateString);
        const dayName = days[date.getUTCDay()];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const hours = date.getUTCHours();
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);

        return `${dayName} ${day} ${month} ${hours}:${minutes}`;
    }
    return (
        <div className='Feed'>
            <div className='feed-nav'>

                <div className='feed-nav-buttons'>
                    <h2>Feed</h2>
                    <button onClick={GotoProfile}>Profile</button>
                    <button onClick={handleLogout}>Déconnexion</button>
                </div>
            </div>
            <div className='feed-container'>

                <div className='feed-container-posts'>
                    <form className='container-posting' onSubmit={handleSubmitPost}>
                        <textarea value={newPostContent} onChange={handleNewPostChange} placeholder="Écrivez quelque chose..." />
                        <button type="submit">Poster</button>
                    </form>
                    <div className='container-posts'>
                        {posts.map(post => (
                            <div className='individualpost' key={post.post_id}>
                                <div className='poster'>
                                    <img src={post.profilePictureUrl} alt={`${post.username}'s profile`} />
                                    <div className='post-username'>{post.username}</div>
                                    <div className='post-date'>{post.formattedDate}</div>
                                </div>
                                <div className='post-content'>{post.content}</div>
                                <div className='post-interactions'>
                                    <span onClick={() => handleLikeClick(post.post_id)}>
                                        <FcLike />
                                    </span>
                                    <span>{post.likes_count} J'aime</span>
                                    <span onClick={() => goToPost(post.post_id)}>
                                        <FcComments />
                                    </span>

                                    <span>{post.comments_count} Commentaires</span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Feed;